import { z } from 'zod';
import { AVAILABLE_COINS } from './accounts.model';

/**
 * Криптосети известные приложению
 */
export enum NETWORK {
  /**
   * Tron network
   */
  trc20 = 'TRC20',
  /**
   * Binance network
   */
  bep20 = 'BEP20',
  /**
   * TON
   */
  ton = 'TON',
}

/**
 * Доступные для работы сети
 */
export const AVAILABLE_NETWORKS = [NETWORK.trc20, NETWORK.bep20] as const;

/**
 * Модель для информации о сети монеты
 */
export const modelCoinNetwork = z.object({
  /**
   * Короткий код
   * Например: TRX
   */
  code: z.string(),
  /**
   * Код сети
   */
  network: z.enum(AVAILABLE_NETWORKS),
  /**
   * Признак её доступности для работы
   */
  isAvailable: z.boolean(),
});

/**
 * Модель с информацией о монете
 */
export const modelCoinInfo = z.object({
  /**
   * Имя монеты
   * Например: Tether USD
   */
  name: z.string(),
  /**
   * Короткий код монеты
   * Например: USDT
   */
  code: z.string(),
  /**
   * Доступна ли монета для работы
   */
  isAvailable: z.boolean(),
  /**
   * Сети монеты
   */
  networks: z.array(modelCoinNetwork),
});

/**
 * Модель с информацией о всех монетах
 */
export const modelCoinsInfoResponse = z.array(modelCoinInfo);

/**
 * Тип с информацией о всех монетах
 */
export type ModelCoinsInfoResponse = z.infer<typeof modelCoinsInfoResponse>;

/**
 * Модель курса покупки/продажи монет
 */
export const modelRateInfo = z.object({
  /**
   * Код монеты
   */
  coin: z.enum(AVAILABLE_COINS),

  /**
   * Сколько фиата за покупку 1 монеты
   */
  buyRate: z.string(),

  /**
   * Сколько фиата за продажу 1 монеты
   */
  sellRate: z.string(),
});

/**
 * Модель с информацией о всех обменных курсах
 */
export const modelRatesInfo = z.array(modelRateInfo);

/**
 * Тип с информацией о всех обменных курсах
 */
export type ModelRatesInfo = z.infer<typeof modelRatesInfo>;
